body,
html,
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

html.sr {
  display: none;
}
